.container {
  margin: 3rem;

  :global {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.tile {
  min-height: auto;
}
